// THESE PATHS COULD DIFFER BETWEEN DESIGN AND DEVELOPMENT
// IMPORT STATEMENTS ARE ALSO HARD CODED, PLEASE CHECK!
$fonts-dir : "/assets/fonts/";
$img-dir : "/assets/images/";
$bower-comp-dir : "/assets/lib/";
$fa-font-path: $bower-comp-dir + "font-awesome/fonts/";
// THESE PATHS COULD DIFFER BETWEEN DESIGN AND DEVELOPMENT

@import "vendor/font_awesome";
@import "vendor/foundation/foundation";
@import "vendor/ie10-viewport-bug-workaround";
